.ant-layout-header {
    padding-left: 24px;
}

.header__logout {
    float: right;
}

.header__logo {
    width: 100%;
    display: inline;
}

.header__logout svg {
    fill: #ffffff;
    width: 18px;
    height: 18px;
}

.site-layout-current-course {
    font-weight: 600;
    padding: 12px 24px;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.site-layout-current-course-link {
    font-size: 14px;
    color: white;
    text-decoration: underline;
    display: flex;
    line-height: 2.5;
}

.header__menu-outlined {
    display: none;
}

.header_sider {
    display: block;
}

@media only screen and (max-width: 1024px) {
    .header__menu-outlined {
        display: block;
    }

    .site-layout-current-course-link {
        font-size: 16px;
    }

    .ant-layout-header {
        padding: 0 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header__logo {
        width: auto;
    }

    .header_sider_is_responsive {
        display: none;
    }

    .header_sider_is_responsive_open {
        display: block;
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
}
